import {
  Datagrid,
  List,
  TextField,
  TextInput,
  BooleanField,
  SelectInput,
  useRecordContext,
  useDataProvider, FunctionField, DateInput,

} from "react-admin";
import { sourceTypeChoices } from "../Organizations/common";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationReferenceField from "../Organizations/OrganizationReferenceField";
import { inventoryStatusChoices } from "./common";
import {format, parseISO} from "date-fns";
import {displayAsMoney} from "../../utils/helpers";
interface ProductionFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const ProductionField: FC<ProductionFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record.productionId ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record.productionId],
    })}`} target="_blank">
      {`${record.productionId}`}
    </Link>
  ) : null;
};


const inventoryFilter = [
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
    alwaysOn
  />,
  <TextInput source="sourceProductionKey" />,
  <TextInput source="productionName" />,
  <TextInput source="productionId" label="Production ID" />,
  <DateInput
      label=" Production Date From"
      source="localDateTime_gte"
      />,
  <DateInput
      label="Production Date To"
      source="localDateTime_lte"
  />,
  <TextInput source="venueName" />,
  <SelectInput source="sourceType" choices={sourceTypeChoices} />,
  <SelectInput source="inventoryStatus" choices={inventoryStatusChoices} />,

];

export const InventoryList = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getList("organizations", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setOrganizations(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);

  return (
    <List filters={inventoryFilter}>
      <Datagrid>
        <TextField source="id" />
        <OrganizationReferenceField organizations={organizations} source="Organization" />
        <ProductionField source="productionId"/>
        <TextField source="productionName" />
        <FunctionField
            source="localDateTime"
            label="Local DateTime"
            render={(record: { localDateTime: string }) =>
                format(parseISO(record.localDateTime), "EEEE MMMM d, yyyy h:mm a")}
        />
        <TextField source="venueName" />
        <TextField source="section" />
        <TextField source="row" />
        <TextField source="quantity" />
        <TextField source="startSeat" sortable={false}/>
        <TextField source="endSeat" sortable={false}/>
        <TextField source="stockType" />
        <TextField source="seatType" sortable={false}/>
        <FunctionField
            source="costPrice"
            label="Cost Price"
            sortable={false}
            render={(record: { costPrice: number }) =>
                displayAsMoney(record.costPrice)}
        />
        <FunctionField
            source="listPrice"
            label="List Price"
            render={(record: { listPrice: number }) =>
                displayAsMoney(record.listPrice)}
        />
        <FunctionField
            source="soldPrice"
            label="Sold Price"
            sortable={false}
            render={(record: { soldPrice: number }) =>
                displayAsMoney(record.soldPrice)}
        />
        <TextField source="splitRule" sortable={false}/>
        <BooleanField source="isInHand" />
        <BooleanField source="isParking" />
        <BooleanField source="isShared" sortable={false}/>
        <TextField source="inventoryStatus" sortable={false}/>
        <TextField source="validSplits" sortable={false}/>
        <TextField source="externalNotes" sortable={false}/>
        <TextField source="sourceKey" sortable={false}/>
        <TextField source="sourceProductionKey" sortable={false}/>
        <TextField source="sourceType" sortable={false}/>
        <TextField source="inHandDate" />
        <TextField source="internalNotes" sortable={false}/>
        <TextField source="attributes" sortable={false}/>
        <FunctionField
            source="createdDate"
            label="Created Date"
            render={(record: { createdDate: string }) =>
                format(parseISO(record.createdDate), "EEEE MMMM d, yyyy h:mm a")}
        />
        <TextField source="lastModifiedBy" sortable={false}/>
        <FunctionField
            source="lastModifiedDate"
            label="Last Modified Date"
            render={(record: { lastModifiedDate: string }) =>
                format(parseISO(record.lastModifiedDate), "EEEE MMMM d, yyyy h:mm a")}
        />
      </Datagrid>
    </List>
  );
};
